import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { styled, Box, width } from "@mui/system";

import { Container, GridContainer } from "./../../grid";
import Logo from "./../elements/Logo";
import SearchBtn from "./../search/SearchBtn";
import MenuBtn from "./MenuBtn";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import CookieNotice from "../CookieNotice";
import { max } from "lodash";

const Tagline = styled("p")(
  ({ theme }) => `
    font-family: var(--nexus-sans);
    font-size: var(--fs-s);
    margin: 0;
    color: var(--grey5);
    ${theme.breakpoints.up("lg")} {
      // font-size: var(--fs-m);
    }
`
);

const Header = ({ globalInfo, data }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const siteName = globalInfo.wp.generalSettings.title;
  const siteDesc = globalInfo.wp.generalSettings.description;
  // console.log(globalInfo)

  const categoriesLinks = globalInfo.categories.nodes.map(cat => ({
    label: cat.name,
    to: `/${cat.slug}`,
  }));

  const latestLink = `/${globalInfo.currentIssue.slug}`;

  const backIssues = globalInfo.issues.nodes.filter(
    issue => !issue.issueFields.featured
  );
  const issuesLinks = backIssues.map(issue => ({
    label: issue.title,
    to: `/${issue.slug}`,
  }));

  const menuContent = [
    {
      type: "single",
      label: "Latest Issue",
      to: latestLink,
    },
    {
      type: "multiple",
      label: "Articles",
      items: [...categoriesLinks],
    },
    {
      type: "multiple",
      label: "Back Issues",
      items: [...issuesLinks],
    },
    {
      type: "single",
      label: "Events",
      to: "/events",
    },
    {
      type: "single",
      label: "Order",
      to: "/order",
    },
    {
      type: "single",
      label: "About",
      to: "/about",
    },
  ];

  return (
    <header>
      {/* Cookie Notice */}
      <CookieNotice />

      {/* Above the logo section */}
      <Container>
        <Box
          sx={theme => ({
            my: 5,
            mt: {
              xs: 3,
              md: 4,
            },
            mb: {
              md: 10,
            },
          })}
        >
          <GridContainer alignItems="center">
            {/* Tagline */}
            <Grid item xs={6} md2={12}>
              <Tagline>{siteDesc}</Tagline>
            </Grid>

            {/* Mobile icons */}
            <Grid
              item
              xs={6}
              md2={12}
              sx={theme => ({
                display: "flex",
              })}
            >
              {!md && (
                <SearchBtn
                  sx={theme => ({
                    fontSize: "1.75rem",
                    padding: theme.spacing(4),
                    ml: "auto",
                  })}
                />
              )}
              {!md && <MenuBtn />}
            </Grid>
          </GridContainer>
        </Box>
      </Container>

      {/* Logo */}
      <Container>
        <Box
          sx={theme => ({
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          })}
        >
          <Logo
            sx={theme => ({
              maxWidth: theme.breakpoints.values.md,
              width: "100%",
            })}
            mb={{
              xs: 6,
              md: 4,
            }}
          />
        </Box>
      </Container>

      {md ? (
        <MenuDesktop menuContent={menuContent} />
      ) : (
        <MenuMobile menuContent={menuContent} />
      )}
    </header>
  );
};

export default Header;
