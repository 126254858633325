import React from "react";
import { Box, styled } from "@mui/system";
import { Link } from "gatsby";
import { Menu, MenuItem } from "@mui/material";
// import ExpandMore from '@mui/icons-material/ExpandMore';
import { MenuExpend } from "../icons";
import { Container } from "./../../grid";
import SearchBtn from "./../search/SearchBtn";

const ListItem = styled("li", {
  shouldForwardProp: prop => prop !== "open",
})(
  ({ theme, open }) => `
    font-family: var(--nexus-type);
    // color:  ${open ? "var(--green3)" : "var(--green4)"};
    color: var(--green4);
    font-weight: bold;
    font-size: var(--fs-s);
    transition: all 0.3s ${theme.transitions.easing.easeOutCubic};
    line-height: 1;
    margin:0;
    padding:0;
    background-color: ${open ? "var(--green2)" : "transparent"};

    & button, & a {
      padding: ${theme.spacing(3)};
      padding-top: ${theme.spacing(2)};
      margin:0;
      font-family: var(--nexus-type);
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 1.8rem;

      span {
        margin-right: 0.25rem;
        display: block;
      }
    }


    &:hover {
      background-color: var(--green2);
      color: var(--green4);
    }

    ${theme.breakpoints.up("lg")} {
      font-size: var(--fs-s);
    }
`
);

const SubMenu = styled(Menu)(
  ({ theme }) => `
    & .MuiMenu-paper {
      background-color: var(--green1);
      box-shadow: none;
      border-radius: 0;
      min-width: 250px;
      border: none;
      padding:0;
      margin:0;
    }
    & .MuiMenuItem-root a {
      width: 100%
    }
`
);

const NavItem = ({ element }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    event.preventDefault();
    // console.log('click')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return element.type === "single" ? (
    <ListItem>
      <Link to={element.to}>{element.label}</Link>
    </ListItem>
  ) : (
    <>
      <ListItem open={open} to={element.to}>
        <button onClick={handleClick} aria-label={element.label}>
          <span>{element.label} </span>
          <MenuExpend />
        </button>
      </ListItem>
      <SubMenu
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={theme => ({
          "& .MuiMenuItem-root:hover": {
            bgcolor: "var(--green2)",
          },
        })}
      >
        {element.items.map(el => (
          <MenuItem key={el.label} onClick={handleClose}>
            <Link to={el.to}>{el.label}</Link>
          </MenuItem>
        ))}
      </SubMenu>
    </>
  );
};

export default function MenuDesktop({ menuContent }) {
  // console.log(menuContent)

  return (
    <Container>
      <Box
        component={"nav"}
        sx={theme => ({
          display: "flex",
          alignItems: "center",
          mb: 3,
          borderTop: "1px solid var(--grey4)",
          borderBottom: "1px solid var(--grey4)",
        })}
      >
        <Box
          sx={theme => ({
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            listStyle: "none",
            margin: 0,
            padding: 0,
            // ml: -3
          })}
          component={"ul"}
        >
          {menuContent.map(element => (
            <NavItem key={element.label} element={element} />
          ))}
        </Box>
        <SearchBtn
          sx={theme => ({
            fontSize: {
              xs: "1.5rem",
            },
            padding: theme.spacing(3),
            ml: "auto",
          })}
        />
      </Box>
    </Container>
  );
}
